<template>
  <div class="page">
    <Navbar title="全部分类" />
    <van-grid>
      <van-grid-item
        :text="classify.name"
        v-for="classify in classifys"
        :key="classify.code"
        @click="classifySearch(classify.code)"
      >
        <template #icon>
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="classify.icon"></use>
          </svg>
        </template>
      </van-grid-item>
    </van-grid>
    <Share ref="share" />
  </div>
</template>
<script>
import { Grid, GridItem } from 'vant'
import Navbar from '../../common/Navbar.vue'
import '../../../../assets/icon/font-icon.js'
import Share from '../../common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
  },
  data () {
    return {
      classifys: []
    }
  },
  mounted () {
    this.retrieveClassifys()
    this.$refs.share.default('main')
  },
  methods: {
    async retrieveClassifys () {
      var pd = { upperCode: '0' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/classify/retrieveClassifyList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.classifys = res.data
      }
    },
    classifySearch (code) {
      this.$router.push({ path: '/mde/train/search', query: { classifyCode: code } })
    }
  }
}
</script>
<style lang="less" scoped>
.icon {
  width: 30px;
  height: 30px;
}
</style>
